<template>
  <!-- ----------------------------------------------------------------------------- -->
  <!-- Employee Profile -->
  <!-- ----------------------------------------------------------------------------- -->
  <b-card class="w-100 mb-4" no-body>
    <div class="p-35">
      <div class="d-flex align-items-start">
        <div>
          <h4 class="card-title mb-1">User Profile</h4>
          <b-card-text class="text-muted">
            To use add bg-light py-3 class in the every row styling.
          </b-card-text>
        </div>
      </div>
    </div>
    <hr class="m-0" />
    <b-card-body>
      <!-- part 1 start -->
      <h4 class="card-title">User Info</h4>
      <b-row class="py-3 border-bottom align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="company"> User Type </label>
        </b-col>
        <b-col sm="9">
          <b-form-select
            v-model="form.userType"
            :options="userOptions"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> First Name </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="fname"
            type="text"
            v-model="form.profile.first_name"
            placeholder="First Name"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> Last Name </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="lname"
            type="text"
            v-model="form.profile.last_name"
            placeholder="Last Name"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> Email </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="email"
            type="email"
            v-model="form.email"
            placeholder="Email"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> Password </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="password"
            type="password"
            v-model="form.password"
            placeholder="Password"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> Phone </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="phone"
            type="tel"
            v-model="form.phone"
            placeholder="Phone"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> Education </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="edu"
            type="text"
            v-model="form.profile.education"
            placeholder="Education"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="lname"> Bio </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="bio"
            type="text"
            v-model="form.profile.bio"
            placeholder="About yourself"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="py-3 border-bottom align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="file"> Select Profile Thumbnail </label>
        </b-col>
        <b-col sm="9">
          <b-form-file id="file"></b-form-file>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="notes3"> Subjects </label>
        </b-col>
        <b-col sm="9">
          <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
          <b-form-tags
            id="subjects-component-select"
            v-model="form.profile.subjects"
            size="lg"
            class="mb-2"
            add-on-change
            no-outer-focus
          >
            <template
              v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
            >
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    :disabled="disabled"
                    variant="info"
                    >{{ tag }}</b-form-tag
                  >
                </li>
              </ul>
              <b-form-select
                v-bind="inputAttrs"
                v-on="inputHandlers"
                :disabled="disabled || availableOptions.length === 0"
                :options="availableSubjects"
              >
                <template #first>
                  <!-- This is required to prevent bugs with Safari -->
                  <option disabled value="">Choose Subjects...</option>
                </template>
              </b-form-select>
            </template>
          </b-form-tags>
        </b-col>
      </b-row>
    </b-card-body>  
    <b-card-body class="bg-light" v-if="form.user_type">
      <h4 class="card-title">Teacher Schedule</h4>
      <!-- part 3 start -->
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="date3"> Start Date </label>
        </b-col>
        <b-col sm="9">
          <b-form-input id="date1" type="date" v-model="form.profile.start_date"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="date3"> End Date </label>
        </b-col>
        <b-col sm="9">
          <b-form-input id="date2" type="date" v-model="form.profile.end_date"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="sdate3"> Start Time </label>
        </b-col>
        <b-col sm="9">
          <b-form-input id="sdate1" type="time" v-model="start_time"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="edate3"> End Time </label>
        </b-col>
        <b-col sm="9">
          <b-form-input id="edate2" type="time" v-model="end_time"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="notes3"> WeekDays </label>
        </b-col>
        <b-col sm="9">
          <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
          <b-form-tags
            id="tags-component-select"
            v-model="form.profile.weekdays"
            size="lg"
            class="mb-2"
            add-on-change
            no-outer-focus
          >
            <template
              v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
            >
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    :disabled="disabled"
                    variant="info"
                    >{{ tag }}</b-form-tag
                  >
                </li>
              </ul>
              <b-form-select
                v-bind="inputAttrs"
                v-on="inputHandlers"
                :disabled="disabled || availableOptions.length === 0"
                :options="availableOptions"
              >
                <template #first>
                  <!-- This is required to prevent bugs with Safari -->
                  <option disabled value="">Choose a day...</option>
                </template>
              </b-form-select>
            </template>
          </b-form-tags>
        </b-col>
      </b-row>
      <!-- part 3 end -->
    </b-card-body>
    <div class="p-35 border-top">
      <div class="btn-grp text-right">
        <b-button pill variant="primary" @click="addNewUser()" class="px-4">Save</b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "AddUser",
  data: () => ({
    start_time: null,
    end_time: null,
    form: {
      userType: 0,
      email: null,
      phone: null,
      password: null,
      profile:{
        level_id: null,
        subjects: null,
        first_name: null,
        last_name: null,
        bio: null,
        weekdays:[],
        timings:[800,1600],
        start_date: null,
        end_date: null
      },
      verified: 1
    },
    levels: [
      {
          value: 0,
          text: "A Levels"
      },
      {
          value: 1,
          text: "O Levels"
      },
      {
          value: 2,
          text: "Matric"
      },
      {
          value: 3,
          text: "FA Fsc ICom"
      },
      {
          value: 4,
          text: "Languages"
      },
      {
          value: 5,
          text: "IT Courses"
      }
    ],
    userOptions: [
      {
        value: 0,
        text: "Student",
      },
      {
        value: 1,
        text: "Teacher",
      }
    ],
    yesNoOptions: [
      {
        value: true,
        text: "Yes",
      },
      {
        value: false,
        text: "No",
      }
    ],
    subjects: [
      "Chemistry",
      "Physics",
      "Maths"
    ],
    weekdays: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ]
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["getUsers","saveUser"]),
    initialize() {
      
    },
    addNewUser(){
      if(this.form.user_type){
        this.form.profile.weekdays = this.form.profile.weekdays.map((d, n) => {
          return {
            id: n,
            title: d
          }
        });
        this.form.profile.timings[0] = parseInt(this.start_time.replace(/\D/g,''));
        this.form.profile.timings[1] = parseInt(this.end_time.replace(/\D/g,''));
      }
      this.form.profile = JSON.stringify(this.form.profile);
      this.saveUser(this.form).then(() => {
        this.$bvToast.toast("New User added", {
          title: 'Successfull Operation',
          autoHideDelay: 5000
        });
        this.$router.push({name: "Contacts Table"});
      })
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$refs["edit-modal"].show();
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.items.splice(index, 1);
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.close();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.editedItem.avatar = URL.createObjectURL(file);
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Contact" : "Edit Contact";
    },
    rows() {
      return this.items.length;
    },
    availableOptions() {
      return this.weekdays.filter((opt) => this.form.profile.weekdays.indexOf(opt) === -1);
    },
    availableSubjects() {
      return this.subjects.filter((opt) => this.form.profile.subjects.indexOf(opt) === -1);
    }
  },
  watch: {}
};
</script>